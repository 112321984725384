<template>
  <ion-app>
    <ion-split-pane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-item lines="full"><img class="logo" src="assets/logo.png" /></ion-item>
            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <ion-item lines="full" v-if="p.header" class="header">
                <ion-label class="header">{{ p.title }}</ion-label>
              </ion-item>
              <ion-item lines="full" v-else router-direction="root" :router-link="p.url" detail="false" class="hydrated" :class="{ selected: isSelected(p.url) }">
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
  
        </ion-content>
      </ion-menu>

      <ion-router-outlet id="main-content"></ion-router-outlet>

    </ion-split-pane>
  </ion-app>
</template>

<script>
import { IonApp, IonContent, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import { useRoute } from 'vue-router';
import { archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import "@/lib/pwa";

export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonItem,
    IonLabel, 
    IonList, 
    IonMenu,
    IonMenuToggle, 
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Eltern',
        header: true
      },
      {
        title: 'Start',
        url: '/Start',
      },
      {
        title: 'Persönliche Daten',
        url: '/PersoenlicheDaten',
      },
      {
        title: 'Geburtsterminrechner',
        url: '/Geburtsterminrechner',
      },
      {
        title: 'Checklisten',
        url: '/Checklisten',
      },
      {
        title: 'Mutterpassübersetzer',
        url: '/Mutterpassuebersetzer',
      },
      {
        title: 'Tagebuch',
        url: '/Tagebuch',
      },
      {
        title: 'App weiterempfehlen',
        url: '/App-weiterempfehlen',
      },
      {
        title: 'Kind',
        header: true
      },
      {
        title: 'Termine',
        url: '/Termine',
      },
        /*
      {
        title: 'Tipps & Tricks',
        url: '/Tipps-und-Tricks',
      },
         */
      {
        title: 'Impfungen A-Z',
        url: '/Impfungen-A-Z',
      },
      {
        title: 'U-Untersuchungen',
        url: '/U-Untersuchungen',
      },
      {
        title: 'Klinik',
        header: true
      },
      {
        title: 'Unsere Geburtsklinik',
        url: '/Unsere-Geburtsklinik',
      },
      {
        title: 'Angebote und Kurse',
        url: '/Angebote-und-Kurse',
      },
      {
        title: 'Galerie',
        url: '/Galerie',
      },
      {
        title: 'Kontakte',
        url: '/Kontakte',
      },
      {
        title: 'Infomaterial bestellen',
        url: '/Infomaterial-bestellen',
      },
        /*
      {
        title: 'Unsere Hebammen',
        url: '/Unsere-Hebammen',
      },
      {
        title: 'Veranstaltungen',
        url: '/Veranstaltungen',
      },
      {
        title: 'Beratungssstellen',
        url: '/Beratungsstellen',
      },
         */
      {
        title: 'Impressum',
        url: '/Impressum',
      },
      {
        title: 'Datenschutz',
        url: '/Datenschutz',
      }
    ];

    const route = useRoute();

    return { 
      selectedIndex,
      appPages, 
      archiveOutline,
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      isSelected: (url) => url === route.path ? 'selected' : ''
    }
  }
});
</script>

<style scoped>
ion-list {
  padding-top: 0;
}

.logo {
  padding: 0 20px 20px 20px;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-item::part(native) {
  background: none!important;
}

ion-item.header {
  background-color: var(--ion-color-tertiary);
  color: #000;
  font-weight: bolder;
}

ion-menu.md ion-content {
  /*
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;

   */
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 300;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  /*
  --padding-start: 10px;
  --padding-end: 10px;

   */
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
  font-weight: 700;
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu ion-item ion-label {
  font-weight: 300;
}
ion-menu ion-item.selected ion-label,
ion-menu ion-item.header ion-label{
  font-weight: 500;
}


ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
