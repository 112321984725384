/* eslint-disable no-console */
import {createApp, watch} from 'vue'
import App from './App.vue'
import router from './router';
import { createPinia} from "pinia";
//import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { IonicVue } from '@ionic/vue';
import './registerServiceWorker';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/roboto.css';
import './theme/variables.css';
import './theme/app.css';

/* Libs */
import {get, set} from "idb-keyval";
import { LocalNotifications} from "@capacitor/local-notifications"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import { useBabyStore } from "@/stores/BabyStore.js"

const pinia = createPinia();
const store = useBabyStore(pinia)

watch(pinia.state, (state) => {
  //console.log("Watch", state);
  set("state", JSON.stringify(state));
}, { deep: true })

navigator.serviceWorker.addEventListener('message', event => {
  // event is a MessageEvent object
  //pinia.state.fetchHebammen();
  console.log(event);
});

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(pinia);
  
get("state")
  .then((data) => {
    if(data) {
      data = JSON.parse(data)
      store.$state = data.baby;
    }
    initDataFromApi();
    startApp();
  })
  .catch((e) => {
    console.log("Fehler beim Initialisieren des Stores", e)
    startApp();
  });

function startApp()
{
  router.isReady().then(() => {
    app.mount('#app');
    defineCustomElements(window);
    LocalNotifications.requestPermissions();
  });
}

function initDataFromApi()
{
  if(store.$state.mutterpass.length == 0) {
    console.log("Lade Mutterpass");
    store.fetchMutterpass();
  }

  if(store.$state.termine.termine.length == 0) {
    console.log("Lade Termine");
    store.fetchTermine();
  }

  if(store.$state.terminkategorien.length == 0) {
    console.log("Lade Terminkategorien");
    store.fetchTerminKategorien();
  }

  if(store.$state.impressum.length == 0) {
    console.log("Lade Impressum");
    store.fetchImpressum();
  }

  if(store.$state.datenschutz.length == 0) {
    console.log("Lade Datenschutz");
    store.fetchDatenschutz();
  }

  if(Object.keys(store.$state.geburtsklinik).length == 0) {
    console.log("Lade Geburtsklinik");
    store.fetchGeburtsklinik();
  }

  if(store.$state.galerie.length == 0) {
    console.log("Lade Galerie");
    store.fetchGalerieImages();
  }

  if(store.$state.hebammen.length == 0) {
    console.log("Lade Hebammen");
    store.fetchHebammen();
  }

  if(Object.keys(store.$state.angebote).length == 0) {
    console.log("Lade Angebote");
    store.fetchAngebote();
  }

  if(store.$state.veranstaltungen.length == 0) {
    console.log("Lade Veranstaltungen");
    store.fetchVeranstaltungen();
  }

  if(store.$state.kontakte.length == 0) {
    console.log("Lade Kontakte");
    store.fetchKontakte();
  }

  if(store.$state.beratungsstellen.length == 0) {
    console.log("Lade Beratungsstellen");
    store.fetchBeratungsstellen();
  }

  if(Object.keys(store.$state.checklisten).length == 0) {
    console.log("Lade Checklisten");
    store.fetchChecklisten();
  }

  if(store.$state.impfliste.length == 0) {
    console.log("Lade Impfliste");
    store.fetchImpfliste();
  }

  if(store.$state.uuntersuchungen.length == 0) {
    console.log("Lade U-Untersuchungen");
    store.fetchUUntersuchungen();
  }

}