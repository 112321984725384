const config = {
  //'apiUrl': 'http://babyapp-api.test',
  'apiUrl': 'https://baby-app.space',
  'mandant': '1',
  'klinikUrl': 'https://www.gesundheitnord.de/geburt/geno-baby.html',
  'email': 'Info@Gesundheitnord.de',
  'appName': 'Babyapp GENO-Baby',
  'vonKrankenhaus': 'von den Kliniken Gesundheit Nord | Klinikverbund Bremen',
}

export default config;