import { defineStore } from "pinia";
import { format } from 'date-fns';
import Config from '@/Config';
import * as NotificationService from "@/lib/NotificationService"
import { tageBisZurGeburt } from "@/lib/DateLib";

export const useBabyStore = defineStore("baby", {

  // state
  state: () => {
    return {
      babyname: "",
      benutzername: "",
      benutzertyp: "1",
      geburtstermin: "",
      benachrichtigungszeit: "12",
      termine: {
        termine: [],
        lastUpdate: 0
      },
      terminkategorien: [],
      checklisten: {},
      selectedChecklistenItems: {},
      mutterpass: [],
      impressum: [],
      impfliste: [],
      uuntersuchungen: [],
      datenschutz: [],
      tagebuch: [],
      geburtsklinik: {},
      galerie: [],
      hebammen: [],
      angebote: {},
      veranstaltungen: [],
      kontakte: [],
      beratungsstellen: [],
    }
  },

  // actions
  actions: {
    async fetchMutterpass() {
      this.mutterpass = await fetch(Config.apiUrl + '/api/mutterpass?appId=' + Config.mandant)
        .then(response => response.json());
    },
    async fetchTermine() {
      await fetch(Config.apiUrl + '/api/termine?appId=' + Config.mandant + '&tstamp='+this.termine.lastUpdate)
        .then(response => response.json())
        .then((data) => {
          if(data.termine.length > 0) {
            this.termine.termine = data.termine;
            this.termine.lastUpdate = data.lastUpdate;
            NotificationService.scheduleForAllTermine(data.termine, this.geburtstermin, this.benutzertyp, this.benachrichtigungszeit);
          }
        })
    },
    async fetchTerminKategorien() {
      this.terminkategorien = await fetch(Config.apiUrl + '/api/terminkategorien?appId=' + Config.mandant)
        .then(response => response.json());
    },
    async fetchImpressum() {
      this.impressum = await fetch(Config.apiUrl + '/api/impressum?appId=' + Config.mandant)
        .then(response => response.json());
    },
    async fetchDatenschutz() {
      this.datenschutz = await fetch(Config.apiUrl + '/api/datenschutz?appId=' + Config.mandant)
        .then(response => response.json());
    },
    async fetchGeburtsklinik() {
      this.geburtsklinik = await fetch(Config.apiUrl + '/api/geburtsklinik?appId=' + Config.mandant)
        .then(response => response.json());
    },
    async fetchGalerieImages() {
      this.galerie = await fetch(Config.apiUrl + '/api/galerie?appId=' + Config.mandant)
        .then(response => response.json());
    },
    async fetchHebammen() {
      this.hebammen = await fetch(Config.apiUrl + '/api/hebammen?appId=' + Config.mandant)
        .then(response => response.json())
    },
    async fetchAngebote() {
      this.angebote = await fetch(Config.apiUrl + '/api/angebote?appId=' + Config.mandant)
        .then(response => response.json());
    },
    async fetchVeranstaltungen() {
      this.veranstaltungen = await fetch(Config.apiUrl + '/api/veranstaltungen?appId=' + Config.mandant)
        .then(response => response.json());
    },
    async fetchKontakte() {
      this.kontakte = await fetch(Config.apiUrl + '/api/kontakte?appId=' + Config.mandant)
        .then(response => response.json());
    },
    async fetchBeratungsstellen() {
      this.beratungsstellen = await fetch(Config.apiUrl + '/api/beratungsstellen?appId=' + Config.mandant)
        .then(response => response.json());
    },
    async fetchChecklisten() {
      this.checklisten = await fetch(Config.apiUrl + '/api/checklisten?appId=' + Config.mandant)
        .then(response => response.json());
    },
    async fetchImpfliste() {
      this.impfliste = await fetch(Config.apiUrl + '/api/impfliste?appId=' + Config.mandant)
        .then(response => response.json());
    },
    async fetchUUntersuchungen() {
      this.uuntersuchungen = await fetch(Config.apiUrl + '/api/uuntersuchungen?appId=' + Config.mandant)
        .then(response => response.json());
    },
    setGeburtstermin(geburtstermin) {
      console.log("Geburtstermin geändert");
      this.geburtstermin = geburtstermin;
      NotificationService.cancelAll();
      NotificationService.scheduleForAllTermine(this.termine.termine, this.geburtstermin, this.benutzertyp, this.benachrichtigungszeit);
    },
    setBenutzertype(event) {
      console.log("Benutzertyp geändert");
      console.log("Neuer Benutzertyp: " + event.detail.value);
      this.benutzertyp = event.detail.value;
      NotificationService.cancelAll();
      NotificationService.scheduleForAllTermine(this.termine.termine, this.geburtstermin, this.benutzertyp, this.benachrichtigungszeit);
    }
  },

  // getters
  getters: {
    getMutterpassItem: (state) => {
      return (id) => state.mutterpass.find(el => el.id == id);
    },
    aktuelleTermine: (state) => {
      const _tageBisZurGeburt = tageBisZurGeburt(state.geburtstermin);
      return () => state.termine.termine
        .filter(el => (parseInt(el.startDatum) <= _tageBisZurGeburt && parseInt(el.endDatum) >= _tageBisZurGeburt))
        .filter(el => {
          if(el.eltern == state.benutzertyp || state.benutzertyp == "3") {
            return el;
          }
        });
        //.then(data => { return data.sort((b,a) => parseInt(a.startDatum) > parseInt(b.startDatum) ? 1 : -1) })
    },
    getTermin: (state) => {
      return (id) => state.termine.termine.find(el => el.id == id);
    },
    getTermine: (state) => {
      const _termine = state.termine.termine
        .filter(el => ( parseInt(el.anzeigeDatum) <= state.tageBisZurGeburt ))
        .filter(el => {
          if(el.eltern == state.benutzertyp || state.benutzertyp == "3") {
            return el;
          }
        });
      return _termine;
    },
    getGroupedTermine: (state) => {
      const groupedTermine = groupBy(state.getTermine, "anzeigeDatum");
      const obj = Object
        .entries(groupedTermine)
        .sort((a,b) => b[0]-a[0]);
      return(obj);
    },
    geburtsterminFormatted: (state) => {
      if(state.geburtstermin === "") {
        return "";
      }
      return format(new Date(state.geburtstermin), 'dd.MM.yyyy');
    },
    tageBisZurGeburt: (state) => {
      const geburt = new Date(state.geburtstermin);
      const diff = ((new Date().getTime() - geburt.getTime()));
      const tageBisZurGeburt = diff/24/60/60/1000;
      return tageBisZurGeburt;
    },
    tippsUndTricksKategorien: (state) => {
      return state.terminkategorien.filter(el => el.type === "tipp");
    }
  },
  persist: true,
});

function groupBy(arr, k) {
  return arr.reduce((acc, value) => {
    // Group initialization
    if (!acc[value[k]]) {
      acc[value[k]] = [];
    }

    // Grouping
    acc[value[k]].push(value);

    return acc;
  }, {});
}