import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/Start'
  },
  {
    path: '/Start',
    component: () => import ('../views/StartPage.vue')
  },
  {
    path: '/PersoenlicheDaten',
    component: () => import ('../views/PersoenlicheDatenPage.vue')
  },
  {
    path: '/Mutterpassuebersetzer',
    component: () => import ('../views/MutterPassPage.vue')
  },
  {
    path: '/Mutterpassuebersetzer/:id',
    component: () => import ('../views/MutterPassDetailPage.vue')
  },
  {
    path: '/Tagebuch',
    component: () => import ('../views/TagebuchPage.vue')
  },
  {
    path: '/Tagebuch/:id',
    component: () => import ('../views/TagebuchDetailPage.vue')
  },
  {
    path: '/App-weiterempfehlen',
    component: () => import ('../views/AppEmpfehlenPage.vue')
  },
  {
    path: '/Termine',
    component: () => import ('../views/TerminePage.vue')
  },
  {
    path: '/Termine/:id',
    component: () => import ('../views/TermineDetailPage.vue')
  },
  {
    path: '/Geburtsterminrechner',
    component: () => import ('../views/GeburtsterminrechnerPage.vue')
  },
  {
    path: '/Checklisten',
    component: () => import ('../views/ChecklistenPage.vue')
  },
  {
    path: '/Checklisten/:id',
    component: () => import ('../views/ChecklistenDetailPage.vue')
  },
  {
    path: '/Tipps-und-Tricks',
    component: () => import ('../views/TippsTricksPage.vue')
  },
  {
    path: '/Tipps-und-Tricks/:id',
    component: () => import ('../views/TippsTricksDetailPage.vue')
  },
  {
    path: '/Impfungen-A-Z',
    component: () => import ('../views/ImpfungenPage.vue')
  },
  {
    path: '/Impfungen-A-Z/:id',
    component: () => import ('../views/ImpfungenDetailPage.vue')
  },
  {
    path: '/U-Untersuchungen',
    component: () => import ('../views/UUntersuchungenPage.vue')
  },
  {
    path: '/U-Untersuchungen/:id',
    component: () => import ('../views/UUntersuchungenDetailPage.vue')
  },
  {
    path: '/Unsere-Geburtsklinik',
    component: () => import ('../views/GeburtsklinikPage.vue')
  },
  {
    path: '/Unsere-Geburtsklinik/:id',
    component: () => import ('../views/GeburtsklinikDetailPage.vue')
  },
  {
    path: '/Galerie',
    component: () => import ('../views/GaleriePage.vue')
  },
  {
    path: '/Unsere-Hebammen',
    component: () => import ('../views/HebammenPage.vue')
  },
  {
    path: '/Unsere-Hebammen/:id',
    component: () => import ('../views/HebammenDetailPage.vue')
  },
  {
    path: '/Infomaterial-bestellen',
    component: () => import ('../views/InfomaterialPage.vue')
  },
  {
    path: '/Angebote-und-Kurse',
    component: () => import ('../views/AngeboteKursePage.vue')
  },
  {
    path: '/Angebote-und-Kurse/:id',
    component: () => import ('../views/AngeboteKurseDetailPage.vue')
  },
  {
    path: '/Veranstaltungen',
    component: () => import ('../views/VeranstaltungenPage.vue')
  },
  {
    path: '/Veranstaltungen/:id',
    component: () => import ('../views/VeranstaltungenDetailPage.vue')
  },
  {
    path: '/Kontakte',
    component: () => import ('../views/KontaktePage.vue')
  },
  {
    path: '/Kontakte/:id',
    component: () => import ('../views/KontakteDetailPage.vue')
  },
  {
    path: '/Beratungsstellen',
    component: () => import ('../views/BeratungsstellenPage.vue')
  },
  {
    path: '/Beratungsstellen/:id',
    component: () => import ('../views/BeratungsstellenDetailPage.vue')
  },
  {
    path: '/Impressum',
    component: () => import ('../views/ImpressumPage.vue')
  },
  {
    path: '/Datenschutz',
    component: () => import ('../views/DatenschutzPage.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
