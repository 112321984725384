import { toastController } from '@ionic/vue';
import { informationCircle } from 'ionicons/icons';

let refreshing = false;
let registration = null;
let updateExists = false;

document.addEventListener('swUpdated', updateAvailable, { once: true })
document.addEventListener('test', updateAvailable)

function updateAvailable(event) {
  registration = event.detail
  updateExists = true
  openUpdateNotification()
}

function refreshApp() {
  updateExists = false
  console.log(registration)
  // Make sure we only send a 'skip waiting' message if the SW is waiting
  if (!registration || !registration.waiting) return
  // send message to SW to skip the waiting and activate the new SW
  registration.waiting.postMessage({ type: 'SKIP_WAITING' })
}

async function openUpdateNotification() {
  const toast = await toastController
    .create({
      header: 'Update verfügbar',
      message: 'Bitte bestätigen um die neue Version zu installieren.',
      icon: informationCircle,
      position: 'top',
      color: 'secondary',
      buttons: [
        {
          side: 'end',
          text: 'Aktualisieren',
          handler: () => {
            console.log('Die App wird nun aktualisiert.');
            refreshApp();
          }
        }
      ]
    })
  await toast.present();
}

// Prevent multiple refreshes
navigator.serviceWorker.addEventListener('controllerchange', () => {
  if (refreshing) return
  refreshing = true
  // Here the actual reload of the page occurs
  window.location.reload()
})