import { LocalNotifications} from "@capacitor/local-notifications"
import {addHours, startOfDay} from "date-fns";
import {tstampFromTerminTage} from "@/lib/DateLib";

async function schedule(id,title, body, at)
{
  await LocalNotifications.schedule({
    notifications: [
      {
        id: id.toString(),
        title: title,
        body: body,
        schedule: {
          at: at
        }
      }
    ]}
  )
}

async function cancelAll()
{
  console.log("cancelAllTermine");
  await LocalNotifications.getPending()
    .then((data) => data.notifications)
    .then((notifications) => {
      LocalNotifications.cancel({notifications: notifications});
    });
}

async function scheduleForAllTermine(termine, geburtstermin, benutzertyp, uhrzeit)
{
  console.log("scheduleForAllTermine");
  const heute = Date.now();
  let notificationTermine = [];

  for(const termin of termine) {
    const tstamp = tstampFromTerminTage(termin.anzeigeDatum, geburtstermin);
    if(tstamp > heute && benutzertyp == termin.eltern) {
      let notificationTime = startOfDay(tstamp);
      notificationTime = addHours(notificationTime, uhrzeit);
      const notification = {
        id: termin.id,
        title: termin.titelDetail,
        schedule: {
          at: new Date(notificationTime)
        }
      }
      notificationTermine.push(notification);
    }
  }

  console.log("Folgende Benachrichtigungen werden angelegt:");
  console.log(notificationTermine);
  LocalNotifications.schedule({notifications: notificationTermine});
}

export { schedule, scheduleForAllTermine, cancelAll }