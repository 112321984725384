import { format } from 'date-fns';

function asString(tstamp) {
  return format(new Date(tstamp), 'd.M.yyyy')
}

function asTime(tstamp) {
  return format(new Date(tstamp), 'H:mm')
}

function asStringWithTime(tstamp) {
  return format(new Date(tstamp), 'dd.MM.yyyy HH:mm')
}

function formattedDateFromDaysToGeburtstermin(days, geburtstermin)
{
  const tstamp = geburtstermin + (parseInt(days)*24*60*60*1000);
  return format(new Date(tstamp), 'dd.MM.yyyy');
}

function tageBisZurGeburt(intGeburtstermin)
{
  const diff = ((new Date().getTime() - intGeburtstermin));
  return diff/24/60/60/1000;
}

function tstampFromTerminTage(tage, geburtstermin)
{
  return geburtstermin + (parseInt(tage) * 1000*60*60*24);
}

export {asString, asStringWithTime, formattedDateFromDaysToGeburtstermin, asTime, tageBisZurGeburt, tstampFromTerminTage };